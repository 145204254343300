define("discourse/plugins/discourse-hcaptcha/discourse/templates/connectors/create-account-after-user-fields/hcaptcha-fields-connector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="input-group">
    <HCaptcha @siteKey={{this.siteSettings.hcaptcha_site_key}} />
  </div>
  */
  {
    "id": "J5OKOJH6",
    "block": "[[[10,0],[14,0,\"input-group\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@siteKey\"],[[30,0,[\"siteSettings\",\"hcaptcha_site_key\"]]]],null],[1,\"\\n\"],[13]],[],false,[\"h-captcha\"]]",
    "moduleName": "discourse/plugins/discourse-hcaptcha/discourse/templates/connectors/create-account-after-user-fields/hcaptcha-fields-connector.hbs",
    "isStrictMode": false
  });
});